import React from 'react';
import {Button, Card, CardContent, Modal, ModalClose, ModalDialog, Stack,} from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";


// components
import UserContext from "../../context/UserContext";
import UserModal from "../User/UserModal";
import BaseApi from "../../utils/BaseApi";

// icons
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import CastIcon from "@mui/icons-material/Cast";


class WebinarRecording extends React.Component {
    static contextType = UserContext

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        }
    }

    async setModalOpenTrue(){
        let status = await this.recording();
        if (status === 201){
            this.setState({
                modalOpen: true,
            });
        }
    }


    async setModalOpenFalse() {

        this.setState(
            {
                modalOpen: false,
            }
        );
    }

    async recording(){
        const api = new BaseApi();
        const url = `webinar/${this.props.webinar.id}/recording/`;
        let response = await api.get(
            url,
            {}
        );
        return response.status
    }

    render() {
        if (this.props.webinar.vk_id && this.props.webinar.vk_oid && this.props.webinar.vk_hash && !this.context.userObject){
            return (
                <Card
                    color={'primary'}
                    variant="soft"
                    sx={{
                        mb: 2
                    }}
                >
                    <CardContent>
                        <Stack
                            direction={'row'}
                            spacing={2}
                            useFlexGap
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                                flexWrap: 'wrap'
                            }}
                        >
                            <CastIcon/>
                            <Typography
                            >
                               Запись доступна! Для просмотра:
                            </Typography>
                            <UserModal
                                hideWhenLoggedIn={true}
                            />
                        </Stack>
                    </CardContent>
                </Card>
            )
        }
        if (this.props.webinar.vk_id && this.props.webinar.vk_oid && this.props.webinar.vk_hash && this.context.userObject){
            return (
                <>
                    <script src="https://vk.com/js/api/videoplayer.js" />
                    <Stack
                        sx={{
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                        }}
                    >
                        <Button
                            onClick={this.setModalOpenTrue.bind(this)}
                            startDecorator={<OndemandVideoIcon />}
                            color={'primary'}
                            sx={{

                            }}
                        >
                            Посмотреть запись
                        </Button>
                    </Stack>
                    <Modal
                        open={this.state.modalOpen}
                    >
                        <ModalDialog
                            layout={'center'}
                            size="lg"
                        >
                            <ModalClose
                                onClick={this.setModalOpenFalse.bind(this)}
                            />
                            <Box
                                sx={{
                                    overflowY: 'scroll',
                                    maxWidth: 'calc(80vw)',
                                    width: 'calc(80vw)',
                                    maxHeight: 'calc(80vh)',
                                    height: 'calc(80vh)',
                                    pr: 2
                                }}
                            >
                                <iframe
                                    title={'VK'}
                                    src={`https://vk.com/video_ext.php?oid=-${this.props.webinar.vk_oid}&id=${this.props.webinar.vk_id}&hd=2&hash=${this.props.webinar.vk_hash}&js_api=1`}
                                    width="100%"
                                    height="100%"
                                    allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                    frameBorder="0"
                                    allowFullScreen
                                />
                            </Box>
                        </ModalDialog>
                    </Modal>
                </>
            )
        }
        return(
            null
        )
    }
}

export default WebinarRecording