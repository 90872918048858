import React from 'react';

import {Card, CardContent, Stack} from "@mui/joy";
import Typography from "@mui/joy/Typography";

// components
import UserContext from "../../context/UserContext";
import UserModal from "../User/UserModal";

// icons
import CastIcon from "@mui/icons-material/Cast";


class WebinarOnairNotice extends React.Component {
    static contextType = UserContext

    render() {
        if (this.props.webinar.onair && !this.context.userObject){
            return (
                <Card
                    color={'danger'}
                    variant="soft"
                    sx={{
                        mb: 2
                    }}
                >
                    <CardContent>
                        <Stack
                            direction={'row'}
                            spacing={2}
                            useFlexGap
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                                flexWrap: 'wrap'
                            }}
                        >
                            <CastIcon/>
                            <Typography
                            >
                                Трансляция началась! Для подключения:
                            </Typography>
                            <UserModal
                                hideWhenLoggedIn={true}
                            />
                        </Stack>
                    </CardContent>
                </Card>

            )
        }
        return null
    }
}

export default WebinarOnairNotice